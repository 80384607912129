import * as React from "react";
import { Typography, Link } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useTheme } from "@mui/material/styles";

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function AboutMe() {
  const theme = useTheme();
  const timelineData = [
    {
      subheading: "Nov. 2021 +",
      title: "New Role: Lead Software Engineer at MSKCC.",
    },
    {
      subheading: "Oct. 2019 - Nov. 2021",
      title:
        "Joined Memorial Sloan Kettering Cancer Center as a Senior Software Engineer.",
    },
    {
      subheading: "Aug. 2016 - Sept. 2019",
      title: `Joined Alzheimer's Therapeutic Research Institute  (ATRI), University of Southern California as a Software Engineer.`,
    },
    {
      subheading: "July 2016 - Aug. 2016",
      title: "Interned at Electra Vehicles as Software Developer.",
    },
    {
      subheading: "2014 - 2016",
      title:
        "M.S. in Computer Science at The University of Massachusetts Dartmouth.",
    },
    {
      subheading: "2011 - 2014",
      title:
        "BE. in Computer Engineering at The Vidyalankar Institute of Technology.",
    },
  ];
  console.log(useMediaQuery(theme.breakpoints.down("sm")));
  return (
    <Grid2 container>
      <Grid2
        item
        xs={12}
        sx={{
          paddingTop: 5,
        }}
      >
        <Grid2 container alignItems="center" spacing={2}>
          <Grid2 item>
            <Typography variant="h5" gutterBottom>
              Lead Software Engineer @{" "}
              <Link href="https://www.mskcc.org/">MSKCC</Link>
            </Typography>
          </Grid2>
          {!useMediaQuery(theme.breakpoints.down("sm")) && (
            <Grid2 item>
              <Typography variant="h5" gutterBottom>
                |
              </Typography>
            </Grid2>
          )}
          <Grid2 container alignItems="center" spacing={1}>
            <Grid2 item>
              <Typography variant="h5" gutterBottom>
                Husband
              </Typography>
            </Grid2>
            <Grid2 item>
              <ArrowRightAltIcon />
            </Grid2>
            <Grid2 item>
              <Typography variant="h5" gutterBottom>
                <Link href="https://www.linkedin.com/in/pragnyasrinivasan">
                  Pragnya Srinivasan
                </Link>
              </Typography>
            </Grid2>
          </Grid2>
          {!useMediaQuery(theme.breakpoints.down("sm")) && (
            <Grid2 item>
              <Typography variant="h5" gutterBottom>
                |
              </Typography>
            </Grid2>
          )}
          <Grid2 item>
            <Typography variant="h5" gutterBottom>
              Travel Enthusiast
            </Typography>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 item xs={12}>
        <Typography variant="h5" color="textSecondary">
          I love architecting distributed systems, building data pipelines and
          web applications.
        </Typography>
      </Grid2>
      <Grid2 item xs={12}>
        <Grid2 container>
          <Grid2 item xs={12}>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {timelineData.map((result, index) => {
                return (
                  <TimelineItem key={index}>
                    <TimelineSeparator>
                      <TimelineDot
                        {...(index === 0
                          ? { color: "primary" }
                          : { variant: "outlined" })}
                      />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography color="text.secondary">
                        {result.subheading}
                      </Typography>
                      {result.title}
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
