export const post = `
## New Website Development and Architecture

I love building and playing around with new design for this website. This new version of the website uses [React library](https://reactjs.org/) 
with [MUI v5](https://mui.com/material-ui/getting-started/overview/). It is deployed on AWS with S3 bucket to host assets and cloudfront for distribution.
Finally, the deployment is automated using via Github Actions. See architecture below. \n
The deployment pipeline builds the code using yarn on node lts/gallium (16.18.1). Then assets are synced to S3 bucket and 
cloudfront distribution cache is invalidated. \n
![architecture](https://mihir-website.s3.amazonaws.com/assets/architecture/website/website.png)
`;
