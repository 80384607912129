import * as React from "react";
import { Typography, Link, Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

export default function Footer() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Grid2 container spacing={2} justifyContent="flex-end">
        <Grid2 item>
          <Typography
            variant="body2"
            color="textSecondary"
            component={Link}
            underline="hover"
            href="/blog"
          >
            Blog
          </Typography>
        </Grid2>
        <Grid2 item>
          <Typography
            variant="body2"
            color="textSecondary"
            component={Link}
            underline="hover"
            href="https://github.com/mihir19"
          >
            Github
          </Typography>
        </Grid2>
        <Grid2 item>
          <Typography
            variant="body2"
            color="textSecondary"
            component={Link}
            underline="hover"
            href="https://www.linkedin.com/in/mihirkavatkar/"
          >
            Linkedin
          </Typography>
        </Grid2>
        <Grid2 item>
          <Typography
            variant="body2"
            color="textSecondary"
            component={Link}
            underline="hover"
            href="mailto: mkavatkar157@gmail.com"
          >
            Email
          </Typography>
        </Grid2>
      </Grid2>
    </Box>
  );
}
