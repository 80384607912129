import React, { useState } from "react";
import {
  Typography,
  List,
  ListItem,
  Divider,
  Chip,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import { blogs } from "../../utils/blogs";

export default function Blog() {
  const navigate = useNavigate();
  const tags = new Set(blogs.flatMap((blog) => blog.tags));
  const [filter, setFilter] = useState(null);
  function handleFilter(tag) {
    setFilter(tag);
  }
  function removeFilter() {
    setFilter(null);
  }
  function goToBlog(title, post) {
    const encodedTtitle = window.btoa(title, "base64url");
    navigate(`/blog/${encodedTtitle}`, { state: { post: post } });
  }
  return (
    <Grid2 container mt={4} spacing={2}>
      <Grid2 item xs={12}>
        <Button
          LinkComponent={Link}
          to="/"
          startIcon={<KeyboardArrowLeftIcon />}
          size="small"
        >
          Back to Home
        </Button>
      </Grid2>
      <Grid2 item xs={12}>
        <Grid2 container alignItems="center">
          <Grid2 item sm={12} md={8}>
            <Typography variant="h5" fontWeight="bold">
              Posts
            </Typography>
          </Grid2>
          <Grid2 item sm={12} md={4}>
            <Card variant="outlined" style={{ backgroundColor: "inherit" }}>
              <CardContent>
                <Grid2 container>
                  <Grid2 item xs={12}>
                    <Typography variant="body1" fontWeight="bold">
                      Filter By Tags
                    </Typography>
                  </Grid2>
                  <Grid2 item>
                    <Grid2 container spacing={1}>
                      {Array.from(tags).map((tag, index) => {
                        return (
                          <Grid2 item key={index}>
                            <Chip
                              tag={tag}
                              key={tag}
                              label={
                                <Typography variant="subtitle2">
                                  {tag}
                                </Typography>
                              }
                              color="primary"
                              size="small"
                              variant={filter === tag ? "filled" : "outlined"}
                              // onClick={() => handleFilter(tag)}
                              {...(filter !== tag && {
                                onClick: () => handleFilter(tag),
                              })}
                              {...(filter !== null &&
                                filter === tag && { onDelete: removeFilter })}
                            />
                          </Grid2>
                        );
                      })}
                    </Grid2>
                  </Grid2>
                </Grid2>
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 item xs={8}>
        <List>
          {(filter === null
            ? blogs
            : blogs.filter((blog) => blog.tags.includes(filter))
          ).map((blog, blogIndex) => {
            return (
              <React.Fragment key={blogIndex}>
                <ListItem disableGutters>
                  <Grid2 container spacing={1}>
                    <Grid2 item xs={12} pl={0}>
                      <Grid2 container spacing={1} justifyContent="flex-start">
                        {blog.tags.map((tag) => {
                          return (
                            <Grid2 item key={`${blog.title}-${tag}`}>
                              <Chip
                                label={
                                  <Typography variant="subtitle2">
                                    {tag}
                                  </Typography>
                                }
                                color="primary"
                                size="small"
                              />
                            </Grid2>
                          );
                        })}
                      </Grid2>
                    </Grid2>
                    <Grid2 item xs={12}>
                      <Typography
                        variant="subtitle1"
                        component="h2"
                        fontWeight="bold"
                      >
                        {blog.title}
                      </Typography>
                    </Grid2>
                    <Grid2 item xs={12}>
                      <Typography
                        variant="subtitle1"
                        component="h2"
                        color="textSecondary"
                      >
                        {blog.summary}
                      </Typography>
                    </Grid2>
                    <Grid2 item xs={12} pl={0}>
                      <Grid2
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        pl={0}
                      >
                        <Grid2 item>
                          <Grid2 container direction="column">
                            <Grid2 item>
                              <Typography>{blog.author}</Typography>
                            </Grid2>
                            <Grid2 item>
                              <Typography variant="caption">
                                {blog.date}
                              </Typography>
                            </Grid2>
                          </Grid2>
                        </Grid2>
                        <Grid2 item>
                          <Button
                            sx={{
                              textTransform: "none",
                              fontWeight: "bold",
                            }}
                            size="small"
                            endIcon={<KeyboardArrowRightIcon />}
                            onClick={() => goToBlog(blog.title, blog.post)}
                          >
                            Read more
                          </Button>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </Grid2>
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
      </Grid2>
    </Grid2>
  );
}
