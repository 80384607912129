import React from "react";
import { Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Link, useParams } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ReactMarkdown from "react-markdown";
import { blogs } from "../../utils/blogs";

export default function Post() {
  const params = useParams();
  return (
    <Grid2 container mt={4} spacing={2}>
      <Grid2 item xs={12}>
        <Button
          LinkComponent={Link}
          to="/blog"
          startIcon={<KeyboardArrowLeftIcon />}
          size="small"
        >
          Back to Blog
        </Button>
      </Grid2>
      <Grid2 item>
        <ReactMarkdown
          components={{
            a: ({ children, ...props }) => {
              return (
                <Link {...props} style={{ color: "#90caf9" }}>
                  {children}
                </Link>
              );
            },
          }}
        >
          {blogs.filter((blog) => blog.title == window.atob(params.id))[0].post}
        </ReactMarkdown>
      </Grid2>
    </Grid2>
  );
}
