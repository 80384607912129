import * as React from "react";
import { Container } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Grid2 from "@mui/material/Unstable_Grid2";

import Header from "./components/header";
import { Routes, Route } from "react-router-dom";
import AboutMe from "./views/AboutMe";
import Blog from "./views/Blog";
import Post from "./views/Post";
import Footer from "./components/footer";

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function MyApp() {
  const colorMode = React.useContext(ColorModeContext);
  return (
    <Container
      sx={{
        bgcolor: "background.default",
        color: "text.primary",
        height: "100vh",
        overflow: "auto",
      }}
      maxWidth={false}
    >
      <Container maxWidth="lg">
        <Grid2 container>
          <Grid2
            item
            xs={12}
            sx={{
              paddingTop: 5,
            }}
          >
            <Header colorMode={colorMode} />
          </Grid2>
          <Routes>
            <Route
              path="/"
              element={
                <Grid2 item xs={12}>
                  <AboutMe />
                </Grid2>
              }
            />

            <Route
              path="/blog"
              element={
                <Grid2 item>
                  <Blog />
                </Grid2>
              }
            />
            <Route
              path="/blog/:id"
              element={
                <Grid2 item xs={12}>
                  <Post />
                </Grid2>
              }
            />
          </Routes>
        </Grid2>
        <Footer />
      </Container>
    </Container>
  );
}

export default function ToggleColorMode() {
  const [mode, setMode] = React.useState("dark");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "dark" && {
            background: {
              default: "#15202B",
            },
          }),
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <MyApp />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
