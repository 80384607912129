import { post as post1 } from "../blogs/blog1";
export const blogs = [
  {
    tags: ["Architecture", "AWS"],
    title: "New Website Development and Architecture",
    summary:
      "A quick read describing this website and its deployment architecture.",
    author: "Mihir Kavatkar",
    date: "Sat Mar. 04, 2023",
    post: post1,
  },
];
