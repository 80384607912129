import * as React from "react";
import IconButton from "@mui/material/IconButton";
import { Typography, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Grid2 from "@mui/material/Unstable_Grid2";

import LightModeIcon from "@mui/icons-material/LightMode";
import NightlightIcon from "@mui/icons-material/Nightlight";

export default function Header({ colorMode }) {
  const theme = useTheme();

  return (
    <Grid2 container alignItems="center" justifyContent="space-between">
      <Grid2 item xs={11}>
        <Typography variant="h3" style={{ fontWeight: 500 }}>
          Mihir Kavatkar
        </Typography>
      </Grid2>
      <Grid2 item xs={1} textAlign="right">
        <IconButton
          sx={{ ml: 1 }}
          onClick={colorMode.toggleColorMode}
          color="inherit"
        >
          {theme.palette.mode === "dark" ? (
            <Tooltip title="Lights On">
              <LightModeIcon />
            </Tooltip>
          ) : (
            <Tooltip title="Lights Off">
              <NightlightIcon />
            </Tooltip>
          )}
        </IconButton>
      </Grid2>
    </Grid2>
  );
}
